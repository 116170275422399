import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/9F-doA8wSPI"
    bibleGroupSrc="https://pdfhost.io/v/ynHyH4HNB_Microsoft_Word_Bible_Group_Homework_418docx.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="A Joy-finding Pursuit - The Pursuit of Happiness" />
  </Layout>
)

export default SermonPost
